export default {
    enter_valid_email : 'Entrez une adrèsse email valide SVP.',
    enter_valid_url : 'Entrez une adrèsse url valide SVP.',
    'validate_rule_must_have' : 'Ce champ doit avoir',
    'validaterule_at_lest' : 'au moins',
    'validaterule_at_most' : 'au plus',
    'validaterule_number' : 'Ce champ doit être un nombre',
    'validate_rule_field_must_be_unique' : 'Cette valeur existe déjà dans le système et doit être unique.Vous devez entrer une valeur différente de celle assignée au champ  :  ',
    please_enter_a_valid_date : 'Veuillez entrer une date/heure valide SVP.',
    string_length_must_between : 'La chaine doit être comprise entre ',
    this_field_is_required : 'Ce champ est réquis',
    the_fields : 'Les champs ',
    mus_have_the_same_values : '<br/>Doivent avoir les mêmes valeurs',
    contains : "Contient",
    equal : "Est egal",
    not_equal : "Est différent de",
    begin_with : "Commence par",
    end_with : "Finit par",
    less : "Est Inférieur",
    less_or_equal : "Est inférieur ou égal",
    greater : "Est supérieur",
    greater_or_equal : "Est supérieur ou égal",
}