export const SIGN_IN = "/auth/signin";

export const LOGIN = SIGN_IN;

export const SIGN_ON = "/auth/signout";

export const LOG_OUT = SIGN_ON;

export default {SIGN_IN,SIGN_ON,LOGIN,LOG_OUT}

export const SIGNIN_API_PATH = "auth/signin";

export const SIGNOUT_API_PATH = "auth/signout";