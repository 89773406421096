import Field from "./Field"
import TextField from "./TextField";
import SelectField from "./SelectField";
import Switch from "./Switch";
import Checkbox from "./Checkbox";
//import IdField from "./IdField";
//import PieceField from "./PieceField";
import Slider from "./Slider";
import ColorPicker from "./Color";
//import Date from "./Date";
//import Time from "./Time";
import Image from "./Image";
import Tel from "./Tel";
import SelectCountry from "./SelectCountry";
import Html from "./Html";

export default {
    Field,
    TextField, 
    SelectField,
    SelectCountry
    ,Switch
    ,Checkbox
    //,IdField
    //,PieceField
    ,Slider
    ,ColorPicker
    //,Date
    //,Time
    ,Image
    ,Tel
    ,Html
}

export {
    Field,
    TextField, 
    SelectField,
    SelectCountry
    ,Switch
    ,Checkbox
    //,IdField
    //,PieceField
    ,Slider
    ,ColorPicker
    ,ColorPicker as Color
    //,Date
    //,Time
    ,Image
    ,Tel
    ,Html
}