import Screen from "./Screen";
import FormData from "./FormData";
import List from "./FormData/List";


Screen.FormData = FormData;
Screen.List = List;

export default Screen;

export {FormData,List};